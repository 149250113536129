export default [
  {
    title: 'Товары',
    route: 'home',
    icon: 'BoxIcon',
  },
  {
    title: 'Атрибуты',
    route: 'attribute',
    icon: 'AlignJustifyIcon',
    children: [
      {
        title: 'Создание aтрибута',
        route: 'attribute',
      },
      {
        title: 'Значение атрибута',
        route: 'AttributeValue',
      },
      {
        title: 'Тип товара',
        route: 'product-type',
      },

    ],
  },
  {
    title: 'Для кого',
    route: 'ForWho',
  },
  {
    title: 'Бренды',
    route: 'brands',
    icon: 'StarIcon',
  },
  {
    title: 'Основные категории',
    route: 'MainCategory',
    icon: 'ListIcon',
  },
  {
    title: 'Категории',
    route: 'Category',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Категория',
        route: 'Category',
      },
      {
        title: 'Подкатегория',
        route: 'SubCategory',
      },

    ],

  },
  {
    title: 'Скидки',
    route: 'discount',
    icon: 'ClockIcon',
  },
  {
    title: 'Админы',
    route: 'admins',
    icon: 'TvIcon',
    // children: [
    //   {
    //     title: 'Администратор',
    //     route: 'admins',
    //   },
    //   {
    //     title: 'Модератор',
    //     route: 'moderator',
    //   },
    // ],
  },
  {
    title: 'Роли',
    route: 'roles',
  },
  {
    title: 'Список заказов',
    route: 'orders',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'Отзывы',
    route: 'reviews',
    icon: 'FileTextIcon',
  },
  {
    title: 'Магазины',
    route: 'stores-name',
    icon: 'CopyIcon',
    children: [
      {
        title: 'Название магазинов',
        route: 'stores-name',
        icon: 'CopyIcon',
      },
      {
        title: 'Адреса магазинов',
        route: 'stores',
        icon: 'CopyIcon',
      },
    ],
  },
  {
    title: 'Пользователи',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'Баннеры',
    route: 'banners',
    icon: 'ImageIcon',
  },
  {
    title: 'Быстрые ссылки',
    route: 'quick-links',
    icon: 'ImageIcon',
  },
  {
    title: 'Промокоды',
    route: 'promo-codes',
    icon: 'KeyIcon',
  },
  {
    title: 'Новости',
    route: 'news',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Все новости',
        route: 'news',
        icon: 'CopyIcon',
      },
      {
        title: 'Создать',
        route: 'create-news',
        icon: 'CopyIcon',
      },
    ]
  },
]
